import React from "react";
import {
    Paper, Divider, TextField, Button, Typography, Grid
} from '@mui/material';
import cfAddress from '../../../assets/images/img/cfAddress.png';
import logoSpinner from "../../../assets/images/logo/logo_spinner.png";

export default function ContactView(props) {

    return (
        <Grid container spacing={3} sx={{py: 5, placeContent: "center", bgcolor: "#6d549638"}}>
            <Grid item xs={10} align="center">
                <Grid container spacing={3}>
                    <Grid item xs={12} align="center">
                        <Typography variant="h3" sx={{color: 'primary.main'}}>
                            CONTACT US
                        </Typography>
                        <Divider sx={{mb:3, color: 'primary.main'}}><img src={logoSpinner} width="50px" alt='loading'/></Divider>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sx={12} lg={6} md={6} align="center">
                                <form onSubmit={props.handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} align="center">
                                            <TextField
                                                id="standard-basic" 
                                                label="Full Name" variant="outlined"  
                                                name='fname' value={props.inputField.fname}
                                                onChange={props.handleChange}
                                                required size = "small"
                                                sx={{width:'90%'}}/>
                                        </Grid>
                                        <Grid item xs={12} align="center">
                                            <TextField
                                                id="standard-basic" 
                                                label="Email" variant="outlined"  
                                                name='email' value={props.inputField.email}
                                                type='email'
                                                onChange={props.handleChange}
                                                required size = "small"
                                                sx={{width:'90%'}}/>
                                        </Grid>
                                        <Grid item xs={12} align="center">
                                            <TextField
                                                id="standard-basic" 
                                                label="Subject" variant="outlined"  
                                                name='subject' value={props.inputField.subject}
                                                onChange={props.handleChange}
                                                required size = "small"
                                                sx={{width:'90%'}}/>
                                        </Grid>
                                        <Grid item xs={12} align="center">
                                            <textarea
                                                name="message" value={props.inputField['message']} 
                                                onChange={props.handleChange}
                                                placeholder="Write your message"
                                                required rows="6"
                                                style={{width:'90%', background: '#dfd9e8'}}/>
                                        </Grid>
                                        <Grid item xs={12} align="center" sx={{mb:2}}>
                                            <Button type='submit' className='btn-yellow'>Send Message</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                            <Grid item xs={12} sx={12} lg={6} md={6} align="center">
                                <Grid container spacing={3} align="start" >
                                    <Grid item xs={12}>
                                        <img src={cfAddress} width="100%"/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">
                                            Bhagowal Road
                                        </Typography>
                                        <Typography variant="body2">
                                            Sialkot, PAKISTAN
                                        </Typography>
                                        <Typography variant="body2" color="primary" sx={{fontWeight: 'bold'}}>
                                            info@the7key.com
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{pb:1}}>
                                        <Typography variant="body2" sx={{display: 'flex'}}>
                                            Tel.: <Typography variant="body2" color="primary" sx={{fontWeight: 'bold'}}>
                                            +92 302 6417683 
                                        </Typography>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
